<template>
  <div>
    <div class="tabs is-boxed">
      <ul>
        <li
          v-for="meetingType in location.Meetingtypes"
          @click="setMeetingtypeId(meetingType.MeetingtypeId)"
          :class="{
            'is-active': meetingType.MeetingtypeId === selectedMeetingtypeId,
          }"
          :key="'t_' + meetingType.MeetingtypeId"
        >
          <a>{{ meetingType.MeetingtypeId | getMeetingtypeName }}</a>
        </li>
      </ul>
    </div>

    <div class="section is-medium no-padding-top">
      <DefaultOpeningHours
        :selectedMeetingtypeId="selectedMeetingtypeId"
        :key="'meetingtypeId_' + selectedMeetingtypeId"
      />
    </div>
    <div class="section is-medium">
      <ExceptionOpeningHours
        :selectedMeetingtypeId="selectedMeetingtypeId"
        :key="'meetingtypeId_' + selectedMeetingtypeId"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'opening-hours',

  components: {
    DefaultOpeningHours: () =>
      import('@/components/Locations/OpeningHours/DefaultOpeningHours'),
    ExceptionOpeningHours: () =>
      import('@/components/Locations/OpeningHours/ExceptionOpeningHours'),
  },
  data() {
    return {
      selectedMeetingtypeId: 0,
    }
  },
  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {
    if (this.location) {
      this.selectedMeetingtypeId = this.location.Meetingtypes[0].MeetingtypeId
    }
  },

  methods: {
    setMeetingtypeId(val) {
      this.selectedMeetingtypeId = val
    },
  },
}
</script>

<style lang="scss" scoped></style>
